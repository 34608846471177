import type { InputVariant } from '@domains/shared/types/inputVariant';
import styled from '@emotion/styled';
import type { Theme } from '@lib/styles/themes/type';

export const HiddenNativeCheckbox = styled.input`
    appearance: none;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    outline: none;
`;

interface InputProps {
    variant: InputVariant;
}

const getVariantValue = (
    variant: InputVariant,
    property: 'borderColor' | 'backgroundColor',
    theme: Theme,
    state: 'checked' | 'unchecked',
    interaction: 'base' | 'hover',
): string => {
    const { checkbox } = theme.deprecated.atoms;

    if (variant === 'invalid') {
        return checkbox.invalid[state][property];
    }

    if (variant === 'primary') {
        return checkbox.primary[state][interaction][property];
    }

    return checkbox.default[state][interaction][property];
};

// flex-shrink: /* required when it is inside a different flex element */
export const StyledCheckbox = styled.label<InputProps>`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    transition:
        background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    border-color: ${({ variant, theme }): string =>
        getVariantValue(variant, 'borderColor', theme, 'unchecked', 'base')};
    outline: 0;
    background: ${({ variant, theme }): string =>
        getVariantValue(variant, 'backgroundColor', theme, 'unchecked', 'base')};
    color: ${({ theme }): string => theme.deprecated.atoms.checkbox.icon.color};
    cursor: pointer;
    pointer-events: all;

    &:hover {
        border-color: ${({ variant, theme }): string =>
            getVariantValue(variant, 'borderColor', theme, 'unchecked', 'hover')};
        background-color: ${({ variant, theme }): string =>
            getVariantValue(variant, 'backgroundColor', theme, 'unchecked', 'hover')};
    }

    svg {
        display: none;
    }

    input:focus + & {
        box-shadow: ${({ theme }): string => theme.deprecated.atoms.checkbox.focus.boxShadow};
    }

    input:disabled + & {
        border-color: ${({ theme }): string => theme.deprecated.atoms.checkbox.disabled.unchecked.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.checkbox.disabled.unchecked.backgroundColor};
        cursor: not-allowed;
    }

    input:checked + & {
        border-color: ${({ variant, theme }): string =>
            getVariantValue(variant, 'borderColor', theme, 'checked', 'base')};
        background: ${({ variant, theme }): string =>
            getVariantValue(variant, 'backgroundColor', theme, 'checked', 'base')};

        svg {
            display: inline;
        }

        &:hover {
            border-color: ${({ variant, theme }): string =>
                getVariantValue(variant, 'borderColor', theme, 'checked', 'hover')};
            background-color: ${({ variant, theme }): string =>
                getVariantValue(variant, 'backgroundColor', theme, 'checked', 'hover')};
        }
    }

    input:disabled:checked + & {
        border-color: ${({ theme }): string => theme.deprecated.atoms.checkbox.disabled.checked.borderColor};
        background-color: ${({ theme }): string => theme.deprecated.atoms.checkbox.disabled.checked.backgroundColor};
        cursor: not-allowed;
    }
`;
