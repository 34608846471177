import {
    BreadcrumbsContainer,
    BreadcrumbSeparator,
    Icon,
    LastBreadcrumb,
    LinkBreadcrumb,
    NexusBreadcrumbsContainer,
    NexusLastBreadcrumb,
    NexusLinkBreadcrumb,
    ToggleMoreBreadcrumb,
} from '@domains/shared/components/Breadcrumbs/Breadcrumbs.theme';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTracking } from '@lib/tracking/useTracking';
import ChevronRight from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronRight';
import HomeFilled from '@nexus/lib-react/dist/core/Icon/icons/default/HomeFilled';
import type { JSX } from 'react';
import { Fragment, useContext, useEffect, useState } from 'react';

interface Props {
    breadcrumbItems: Breadcrumb[];
    shouldUseNexusTheme?: boolean;
    shouldShowHomePageIcon?: boolean;
}

export const Breadcrumbs = ({
    breadcrumbItems,
    shouldUseNexusTheme = false,
    shouldShowHomePageIcon = true,
}: Props): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const { trackEvent } = useTracking();
    const [areBreadcrumbsRolled, setAreBreadcrumbsRolled] = useState(isDesktop === false);

    const trackBreadcrumbClick = (trackingData?: Record<string, string>): void => {
        const eventName = trackingData?.eventName ?? 'listing';
        trackEvent(eventName, {
            touch_point_button: 'breadcrumb',
            action_type: trackingData?.actionType,
        });
    };

    const toggleBreadcrumbs = (): void => {
        setAreBreadcrumbsRolled(false);
    };

    useEffect(() => {
        setAreBreadcrumbsRolled(isDesktop === false);
    }, [isDesktop]);

    if (shouldUseNexusTheme) {
        return (
            <NexusBreadcrumbsContainer data-testid="nexus-breadcrumbs-container">
                {areBreadcrumbsRolled ? (
                    <>
                        <ToggleMoreBreadcrumb onClick={toggleBreadcrumbs}>...</ToggleMoreBreadcrumb>
                        <ChevronRight size={8} />
                    </>
                ) : null}
                {breadcrumbItems.map(({ id, label, url, trackingData }, index) => {
                    return index + 1 !== breadcrumbItems.length && url ? (
                        <Fragment key={id}>
                            <LocalLink passHref href={url}>
                                <NexusLinkBreadcrumb
                                    onClick={() => trackBreadcrumbClick(trackingData)}
                                    isHidden={areBreadcrumbsRolled}
                                    data-cy="breadcrumb"
                                >
                                    {shouldShowHomePageIcon && index === 0 ? (
                                        <HomeFilled size={'icon-small'} data-testid="breadcrumbs-icon" />
                                    ) : null}
                                    {label}
                                </NexusLinkBreadcrumb>
                            </LocalLink>
                            {areBreadcrumbsRolled ? null : <ChevronRight size={8} />}
                        </Fragment>
                    ) : (
                        <NexusLastBreadcrumb key={id} data-cy="last-breadcrumb">
                            {label}
                        </NexusLastBreadcrumb>
                    );
                })}
            </NexusBreadcrumbsContainer>
        );
    }

    return (
        <BreadcrumbsContainer>
            {areBreadcrumbsRolled ? (
                <>
                    <ToggleMoreBreadcrumb onClick={toggleBreadcrumbs}>...</ToggleMoreBreadcrumb>
                    <BreadcrumbSeparator src={`${staticAssetsPrefix}/images/search/breadSeparator.svg`} />
                </>
            ) : null}
            {breadcrumbItems.map(({ id, label, url, trackingData }, index) => {
                return index + 1 !== breadcrumbItems.length && url ? (
                    <Fragment key={id}>
                        <LocalLink passHref href={url}>
                            <LinkBreadcrumb
                                onClick={() => trackBreadcrumbClick(trackingData)}
                                isHidden={areBreadcrumbsRolled}
                                data-cy="breadcrumb"
                            >
                                {index === 0 ? <Icon src={`${staticAssetsPrefix}/images/search/homeIcon.svg`} /> : null}
                                {label}
                            </LinkBreadcrumb>
                        </LocalLink>
                        <BreadcrumbSeparator
                            isHidden={areBreadcrumbsRolled}
                            src={`${staticAssetsPrefix}/images/search/breadSeparator.svg`}
                        />
                    </Fragment>
                ) : (
                    <LastBreadcrumb key={id} data-cy="last-breadcrumb">
                        {label}
                    </LastBreadcrumb>
                );
            })}
        </BreadcrumbsContainer>
    );
};
