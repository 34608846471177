import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import type { InputVariant } from '@domains/shared/types/inputVariant';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import type { ChangeEvent, ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef, useRef } from 'react';

import { HiddenNativeCheckbox, StyledCheckbox } from './Checkbox.theme';

interface Props {
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    dataCy?: string;
    id: string;
    name?: string;
    variant?: InputVariant;
    icon?: IconDefinition;
    testId?: string;
}

type PropsWithInputAttributes = Props & Omit<ComponentProps<'input'>, 'type' | 'onChange'>;

const CheckboxElement: ForwardRefRenderFunction<HTMLInputElement, PropsWithInputAttributes> = (
    {
        id,
        className,
        onChange,
        disabled = false,
        name,
        dataCy = 'checkboxButton',
        testId = 'checkbox',
        variant = 'default',
        icon = faCheck,
        ...htmlProps
    },
    ref,
) => {
    const inputElement = useRef<HTMLInputElement | null>();
    const refProxy = (element: HTMLInputElement | null): void => {
        inputElement.current = element;
        if (ref) {
            if (typeof ref === 'function') {
                ref(element); // forward ref
            } else {
                ref.current = element;
            }
        }
    };

    return (
        <>
            <HiddenNativeCheckbox
                {...htmlProps}
                id={id}
                name={name}
                type="checkbox"
                disabled={disabled}
                onChange={onChange}
                data-cy={`${dataCy}-hidden-input`}
                aria-invalid={variant === 'invalid'}
                ref={refProxy}
            />
            <StyledCheckbox variant={variant} htmlFor={id} className={className} data-cy={dataCy} data-testid={testId}>
                <Icon icon={icon} />
            </StyledCheckbox>
        </>
    );
};

export const Checkbox = forwardRef<HTMLInputElement, PropsWithInputAttributes>(CheckboxElement);
