export const SELLER_BADGE_ID = {
    leader: 'quality-leader',
    leaderPro: 'quality-leader-pro',
    otodomAnalytics: 'otodom-analytics',
    pzfdMember: 'pzfd-member',
} as const;

export type SellerBadgeId = ObjectValues<typeof SELLER_BADGE_ID>;

export interface SellerBadge {
    id: SellerBadgeId;
}
