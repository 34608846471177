import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { Locale } from '@lib/i18n/types/locale';
import { useCallback, useMemo } from 'react';

const MAX_DECIMALS = 2;
const DEFAULT_DECIMAL_SEPARATOR = ',';
const RIGHT_TRIM_DECIMAL_SEPARATOR_AND_DECIMAL_ZEROS_ON_BLUR = /([,.]0{0,2})$/;

interface UseNumberFormatReturn {
    formatNumberOnBlur(value: string): string;
    formatNumberOnInput(valueNumber: string | null, rawValue: string): string;
    getNewCaretPosition(rawInputValue: string, formattedValue: string, input: HTMLInputElement): number;
    getNumberValue(rawValue: string): string | null;
    maxLength: number;
    separators: { decimal: string; group: string };
}

const getBaseData = (
    lang: Locale,
): { maxLength: number; maxLengthRawNumber: number; separators: UseNumberFormatReturn['separators'] } => {
    const formatter = new Intl.NumberFormat(lang);
    const parts = formatter.formatToParts(123456.01);
    const group = parts.find(({ type }) => type === 'group')?.value || '';
    const decimal = parts.find(({ type }) => type === 'decimal')?.value || DEFAULT_DECIMAL_SEPARATOR;
    const maxLength = formatter.format(Number.MAX_SAFE_INTEGER / 10).length - 1; // Max length with separators
    const maxLengthRawNumber = String(Number.MAX_SAFE_INTEGER).length - 1;

    return {
        maxLength,
        maxLengthRawNumber,
        separators: {
            decimal,
            group,
        },
    };
};

const formatNumberOnBlur: UseNumberFormatReturn['formatNumberOnBlur'] = (rawValue) =>
    rawValue.replace(RIGHT_TRIM_DECIMAL_SEPARATOR_AND_DECIMAL_ZEROS_ON_BLUR, '');

export const useNumberFormat = (type: 'INTEGER' | 'FLOAT'): UseNumberFormatReturn => {
    const { lang } = useSiteSettings();
    const { maxLength, maxLengthRawNumber, separators } = useMemo(() => getBaseData(lang), [lang]);
    const removeNumberUnusedCharsRegex = useMemo(
        () => ({
            // Safari doesn't support lookbehind regexp, use naive implementation
            // In perfect world we would use
            // new RegExp(`(?<=${separators.decimal === '.' ? '\\.' : separators.decimal}.*)${separators.decimal === '.' ? '\\.' : separators.decimal}`,
            decimalCharacterNormalizer: new RegExp(separators.decimal === '.' ? '\\.' : separators.decimal, 'g'),
            nonNumberChars: new RegExp(`[^0-9${type === 'FLOAT' ? separators.decimal : ''}]`, 'g'),
        }),
        [separators, type],
    );

    const getNumberValue = useCallback<UseNumberFormatReturn['getNumberValue']>(
        (value) => {
            let number = value.replace(removeNumberUnusedCharsRegex.nonNumberChars, '');
            const firstDecimalSeparatorPosition = number.indexOf(separators.decimal);

            if (firstDecimalSeparatorPosition !== -1) {
                number = `${number.slice(0, firstDecimalSeparatorPosition + 1)}${number
                    .slice(firstDecimalSeparatorPosition + 1)
                    .replace(removeNumberUnusedCharsRegex.decimalCharacterNormalizer, '')}`;
            }

            if (number.length === 0) {
                return null;
            }
            const [integer, decimal] = number.split(separators.decimal);

            return `${integer}.${(decimal || '').slice(0, MAX_DECIMALS)}`.slice(0, maxLengthRawNumber);
        },
        [maxLengthRawNumber, removeNumberUnusedCharsRegex, separators],
    );

    const formatNumberOnInput = useMemo<UseNumberFormatReturn['formatNumberOnInput']>(() => {
        if (type === 'FLOAT') {
            return (valueNumber, rawValue): string => {
                if (valueNumber === null) {
                    return '';
                }

                const [, decimals = ''] = valueNumber.split('.');
                const minimumFractionDigits = decimals.length;

                return `${(+valueNumber).toLocaleString(lang, { minimumFractionDigits })}${
                    rawValue.endsWith(separators.decimal) ? separators.decimal : ''
                }`;
            };
        }

        return (valueNumber): string =>
            valueNumber === null ? '' : (+valueNumber).toLocaleString(lang, { maximumFractionDigits: 0 });
    }, [lang, separators, type]);

    const getNewCaretPosition = useCallback<UseNumberFormatReturn['getNewCaretPosition']>(
        (rawInputValue, formattedInputValue, input) => {
            const inputRawValueLength = rawInputValue.length;
            const currentCursorPosition = input.selectionStart;

            if (formattedInputValue.length === 0) {
                return 0;
            }
            if (currentCursorPosition === null || currentCursorPosition === inputRawValueLength) {
                return formattedInputValue.length;
            }
            const rawLastChar = rawInputValue.slice(currentCursorPosition - 1, currentCursorPosition);

            if (separators.decimal === rawLastChar) {
                return formattedInputValue.indexOf(separators.decimal) + 1;
            }

            const inputFormattedValueLength = formattedInputValue.length;
            const rawCursorPositionFromEnd = rawInputValue.length - currentCursorPosition;
            const formattedLastChar = formattedInputValue.slice(
                inputFormattedValueLength - rawCursorPositionFromEnd - 1,
                inputFormattedValueLength - rawCursorPositionFromEnd,
            );

            // Handle PL 5 to 4 digits formatting change
            // 21|{backspace} 034 to 2|034
            if (formattedInputValue.split(separators.decimal)[0].length === 4 && currentCursorPosition === 1) {
                return 1;
            }

            // Move from
            // 123,|456.00 to 123|,456.00
            if (separators.group === formattedLastChar && separators.group !== rawLastChar) {
                return currentCursorPosition;
            }

            return Math.max(0, inputFormattedValueLength - rawCursorPositionFromEnd);
        },
        [separators],
    );

    return {
        formatNumberOnBlur,
        formatNumberOnInput,
        getNewCaretPosition,
        getNumberValue,
        maxLength,
        separators,
    };
};
