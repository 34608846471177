import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import type { ComponentProps, JSX, MouseEventHandler, ReactNode } from 'react';

import { InteractiveIslandContainer, StyledAnchor } from './Navigation.theme';

interface AnchorProps extends ComponentProps<'a'> {
    children: ReactNode;
    href: string;
}

export const Anchor = ({ children, href, ...restProps }: AnchorProps): JSX.Element => {
    return (
        <LocalLink href={href} passHref prefetch={false}>
            <StyledAnchor {...restProps}>{children}</StyledAnchor>
        </LocalLink>
    );
};

interface InteractiveIslandProps {
    children: ReactNode;
    shouldStopEventPropagation?: boolean;
    shouldAffectEntireContainer?: boolean;
    className?: string;
}

export const InteractiveIsland = ({
    children,
    shouldStopEventPropagation,
    shouldAffectEntireContainer = false,
}: InteractiveIslandProps): JSX.Element => {
    const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
        if (!shouldStopEventPropagation) {
            return;
        }

        return event.stopPropagation();
    };

    return (
        <InteractiveIslandContainer shouldAffectEntireContainer={shouldAffectEntireContainer} onClick={handleClick}>
            {children}
        </InteractiveIslandContainer>
    );
};
