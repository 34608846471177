import { AD_PAGE_TYPE } from '@domains/shared/consts/adPageType';

const getAdPageTypeUrlPart = ({ isInvestment }: { isInvestment: boolean | null }): string => {
    return isInvestment ? AD_PAGE_TYPE.investment : AD_PAGE_TYPE.ad;
};

export const generateAdvertLink = ({ isInvestment, slug }: { isInvestment: boolean; slug: string }): string => {
    const adPageTypeUrlPart = getAdPageTypeUrlPart({
        isInvestment,
    });

    return `/[lang]/${adPageTypeUrlPart}/${slug}`;
};
