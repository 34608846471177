import { Input } from '@domains/shared/components/Input/Input';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';
import type { ChangeEvent, FocusEvent, JSX } from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';
import type { ChangeHandler } from 'react-hook-form';

import type { ReactHookFormFields, ReactHookFormRef } from './reactHookFormTypes';
import { useNumberFormat } from './useNumberFormat';

export interface NumberInputProps<T extends ReactHookFormFields = ReactHookFormFields> extends ReactHookFormRef<T> {
    className?: string;
    autoComplete?: 'on' | 'off';
    defaultValue?: string | number | null;
    type?: 'INTEGER' | 'FLOAT';
    inputAppend?: string;
    inputId: string;
    isDisabled?: boolean;
    isParentLocked?: boolean;
    isValid?: boolean;
    onBlur?(promise: ReturnType<ChangeHandler>): void;
    onFocus?(): void;
    onChangeCallback?(): void;
    placeholder?: string;
    shouldUseNexusInput?: boolean;
}

// This component allows applying custom number format and solves the issues mentioned in:
// * https://www.ctrl.blog/entry/html5-input-number-localization.html
// * More in: https://medium.com/takeaway-tech/solving-cross-browsers-localization-on-numeric-inputs-3f7aec57aaeb
//
// Data send to the BE is in format "DDDDD.DD" (e.g. 12345.78 or 12345)
// Data displayed to the user depends on the browser and the current language
const NativeFormNumberField = <T extends ReactHookFormFields = ReactHookFormFields>({
    className,
    autoComplete = 'off',
    clearFormErrors,
    defaultValue,
    inputAppend,
    inputId,
    isDisabled = false,
    isParentLocked,
    isValid = true,
    name,
    onBlur,
    onFocus,
    placeholder,
    register,
    registerOptions,
    setFormValue,
    type = 'INTEGER',
    onChangeCallback,
    shouldUseNexusInput,
}: NumberInputProps<T>): JSX.Element => {
    const { formatNumberOnBlur, formatNumberOnInput, getNewCaretPosition, getNumberValue, maxLength, separators } =
        useNumberFormat(type);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const stringDefaultValue = defaultValue
        ? getNumberValue(String(defaultValue).replace('.', separators.decimal))
        : null;
    // by default format non-numeric inputs by removing all non-number chars
    const formattedStringDefaultValue = formatNumberOnBlur(
        formatNumberOnInput(stringDefaultValue, stringDefaultValue ?? ''),
    );
    const inputVariant = isValid ? undefined : 'invalid';

    const { onBlur: onRegisterBlur, onChange: onRegisterChange, ref, ...inputProps } = register(name, registerOptions);
    const refProxy = (element: HTMLInputElement | null): void => {
        inputRef.current = element;
        ref(element);
    };

    // Reset input value when it is disabled, otherwise set unformatted value to form and formatted to input
    useEffect(() => {
        const { current: input } = inputRef;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Temporarily disable value type check
        // @ts-ignore
        setFormValue(name, (isDisabled && !isParentLocked) || stringDefaultValue === null ? null : +stringDefaultValue);
        if (isDisabled) {
            clearFormErrors(name);
        } else if (input) {
            input.value = formattedStringDefaultValue;
        }
    }, [
        clearFormErrors,
        formattedStringDefaultValue,
        isDisabled,
        isParentLocked,
        name,
        setFormValue,
        stringDefaultValue,
    ]);

    // Be proxy when blur - send a different value to react-hook-form when it is displayed
    const onInputBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>): void => {
            const { currentTarget } = event;
            const currentValue = currentTarget.value;
            const numberValue = getNumberValue(currentValue);
            const target = {
                name: currentTarget.name,
                value: numberValue !== '' && numberValue !== null ? +numberValue : null,
            };
            const returnedValue = onRegisterBlur({
                ...event,
                target,
                currentTarget: target,
            });

            currentTarget.value = formatNumberOnBlur(currentValue);
            onBlur?.(returnedValue);
        },
        [formatNumberOnBlur, getNumberValue, onBlur, onRegisterBlur],
    );
    // Be proxy when input - send a different value to react-hook-form when it is displayed
    const onInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget } = event;
            const currentValue = currentTarget.value;
            const numberValue = getNumberValue(currentValue);

            const newFormattedValue = formatNumberOnInput(numberValue, currentValue);
            const target = {
                name: currentTarget.name,
                value: numberValue !== '' && numberValue !== null ? +numberValue : null,
            };
            const newCursorPosition = getNewCaretPosition(currentValue, newFormattedValue, currentTarget);

            currentTarget.value = newFormattedValue;
            currentTarget.setSelectionRange(newCursorPosition, newCursorPosition);
            onRegisterChange({
                ...event,
                target,
                currentTarget: target,
            });
            onChangeCallback?.();
        },
        [formatNumberOnInput, getNewCaretPosition, getNumberValue, onRegisterChange, onChangeCallback],
    );

    if (shouldUseNexusInput) {
        return (
            <TextInput
                {...inputProps}
                defaultValue={formattedStringDefaultValue}
                suffix={inputAppend}
                autoComplete={autoComplete}
                className={className}
                disabled={isDisabled}
                id={inputId}
                inputMode={type === 'FLOAT' ? 'decimal' : 'numeric'}
                maxLength={maxLength}
                // @ts-expect-error -- TextInput renders <input /> which accepts `onInput`
                onInput={onInputChange}
                onBlur={onInputBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                ref={refProxy}
            />
        );
    }

    return (
        <Input
            {...inputProps}
            append={inputAppend}
            autoComplete={autoComplete}
            className={className}
            defaultValue={formattedStringDefaultValue}
            disabled={isDisabled}
            id={inputId}
            inputMode={type === 'FLOAT' ? 'decimal' : 'numeric'}
            maxLength={maxLength}
            onBlur={onInputBlur}
            onFocus={onFocus}
            onInput={onInputChange}
            placeholder={placeholder}
            ref={refProxy}
            type="text"
            variant={inputVariant}
        />
    );
};

// React.memo drops generics in the returned function
export const NumberInput = memo(NativeFormNumberField) as typeof NativeFormNumberField;
