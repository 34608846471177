import { ESTATE } from '@type/search/filters/estate';

export const SEO_ESTATE = {
    ...ESTATE,
    office: 'OFFICE',
    villas: 'VILLAS',
    penthouses: 'PENTHOUSES',
} as const;

export type SeoEstate = ObjectValues<typeof SEO_ESTATE>;
