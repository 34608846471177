import { TRANSACTION } from '@type/search/filters/transaction';

/**
 * getTransactionTranslationKeyPrefix will return transaction translation key conditioned by transaction type
 * @param {string} transaction - transaction type
 * @returns {string} - transaction translation key
 */
export const getTransactionTranslationKeyPrefix = (transaction: string): string => {
    const transactionTranslationKeys = {
        [TRANSACTION.rent]: 'frontend.shared.seo.transaction-label-rent',
        [TRANSACTION.sell]: 'frontend.shared.seo.transaction-label-sell',
    };

    return transactionTranslationKeys[transaction as keyof typeof transactionTranslationKeys];
};
