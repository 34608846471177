import type { StreetNameAndNumber } from '@type/location/address';

export const getLocationAddress = ({
    street,
    radius = 0,
    locations,
}: {
    street: StreetNameAndNumber | null;
    radius?: number;
    locations: { fullName: string }[];
}): string | undefined => {
    const fullNameWithoutStreet = locations[locations.length - 1]?.fullName;

    const isExactLocation = radius === 0;
    const isValidStreetLocation = !!(isExactLocation && fullNameWithoutStreet && street);

    if (isValidStreetLocation) {
        return buildFullNameWithStreet(fullNameWithoutStreet, street);
    }

    return fullNameWithoutStreet;
};

const buildFullNameWithStreet = (fullNameWithoutStreet: string, street: StreetNameAndNumber): string => {
    const streetName = `${street.name} ${street.number || ''}`.trim();

    // Avoid returning only street number if street name is somehow empty.
    const canUseFullNameWithStreet = !!(street.name && streetName);

    if (canUseFullNameWithStreet) {
        return `${streetName}, ${fullNameWithoutStreet}`;
    }

    return fullNameWithoutStreet;
};
