import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const MobileButtonsFooterContainer = styled.footer`
    display: flex;
    position: fixed;

    /* MobileFilters have z-index 700 */
    z-index: 600;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    padding: ${theme.space['16']};
    border-top: 1px solid ${theme.colors.$02_gray};
    background-color: ${theme.colors.$01_white};
    gap: ${theme.space['8']};

    @media ${theme.mediaQueries.lgMin} {
        display: none;
    }
`;

// We need to use custom styles because we don't have link looks like button in Nexus
export const PhoneButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 48px;
    border-radius: ${theme.radii['4']};
    background-color: ${theme.colors['background-brand-primary']};
    color: ${theme.colors.$01_white};
    font-family: ${theme.fonts.family};
    font-size: ${theme.fontSizes['16']};
    font-weight: ${theme.fontWeights.bold};
    text-decoration: none;
`;
