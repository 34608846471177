import styled from '@emotion/styled';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';
import { TextContainer } from '@nexus/lib-react/dist/core/Typography';
import type { CSSProperties } from 'react';

interface BreadcrumbProps {
    isHidden?: boolean;
}

export const BreadcrumbsContainer = styled.div`
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: center;
    white-space: nowrap;
`;

export const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
`;

export const BreadcrumbSeparator = styled.img<BreadcrumbProps>`
    display: ${({ isHidden = false }): string => (isHidden ? 'none' : 'block')};
    height: 8px;
    margin: 0 8px;
`;

export const LinkBreadcrumb = styled.a<BreadcrumbProps>`
    display: ${({ isHidden = false }): string => (isHidden ? 'none' : 'flex')};
    align-items: center;
    color: ${({ theme }): string => theme.deprecated.domains.search.breadcrumbs.link.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    line-height: 21px;
    text-decoration-color: ${({ theme }): string =>
        theme.deprecated.domains.search.breadcrumbs.link.textDecorationColor};
    text-decoration-line: underline;
    &:hover {
        transition: opacity 0.2s linear;
        opacity: 0.7;
    }
`;

export const LastBreadcrumb = styled.span`
    color: ${({ theme }): string => theme.deprecated.domains.search.breadcrumbs.label.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    line-height: 21px;
`;

export const ToggleMoreBreadcrumb = styled.span`
    ${LinkBreadcrumb} {
        text-decoration-line: none;
    }
`;

export const NexusBreadcrumbsContainer = styled.div`
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: center;
    white-space: nowrap;
    gap: ${theme.space[8]};
`;

export const NexusLinkBreadcrumb = styled(TextContainer)<BreadcrumbProps>`
    display: ${({ isHidden }): CSSProperties['display'] => (isHidden ? 'none' : 'flex')};
    align-items: center;
    gap: ${theme.space[8]};
    color: ${theme.colors['text-global-primary']};
`.withComponent('a');

export const NexusLastBreadcrumb = styled(TextContainer)`
    color: ${theme.colors['text-global-secondary']};
    white-space: break-spaces;
`.withComponent('span');
