import type { OptionType } from '@domains/shared/components/Dropdown/types';
import type { JSX } from 'react';
import { useContext } from 'react';
import type { ControlProps, GroupBase, OptionProps } from 'react-select';
import { components as SelectComponents } from 'react-select';

import { DropdownContext } from './Dropdown.context';
import { ControlIconContainer, OptionCheckbox, OptionContent } from './Dropdown.theme';

export interface Components<CustomOptionType extends OptionType = OptionType> {
    /* eslint-disable @typescript-eslint/naming-convention -- 3rd party type */
    Control: <
        Option extends CustomOptionType,
        IsMulti extends boolean = false,
        Group extends GroupBase<Option> = GroupBase<Option>,
    >(
        props: ControlProps<Option, IsMulti, Group>,
    ) => JSX.Element;
    Option: <
        Option extends CustomOptionType,
        IsMulti extends boolean = false,
        Group extends GroupBase<Option> = GroupBase<Option>,
    >(
        props: OptionProps<Option, IsMulti, Group>,
    ) => JSX.Element;
    IndicatorSeparator: () => null;
    /* eslint-enable @typescript-eslint/naming-convention */
}

export const COMPONENTS: Components = {
    Control: ({ children, ...props }) => {
        const { icon } = useContext(DropdownContext);

        return (
            <SelectComponents.Control {...props}>
                {icon ? <ControlIconContainer>{icon}</ControlIconContainer> : null}
                {children}
            </SelectComponents.Control>
        );
    },
    Option: ({ children, ...props }) => {
        const {
            isMulti,
            isSelected,
            isDisabled,
            innerProps: { id },
        } = props;
        const checkboxId = `${id}-checkbox`;

        return (
            <SelectComponents.Option {...props}>
                {isMulti ? (
                    <OptionCheckbox id={checkboxId} checked={isSelected} disabled={isDisabled} readOnly />
                ) : null}
                <OptionContent isMulti={isMulti}>{children}</OptionContent>
            </SelectComponents.Option>
        );
    },
    IndicatorSeparator: () => {
        return null;
    },
};
