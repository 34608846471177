import { Checkbox } from '@domains/shared/components/Checkbox/Checkbox';
import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

interface WrapperProps {
    isActiveFilterField?: boolean;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isMenuOpen?: boolean;
    isMulti?: boolean;
    isPrimary?: boolean;
    shouldShowDropdownIndicator?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    cursor: ${(props): string => (props.isDisabled ? 'not-allowed' : 'auto')};

    .react-select__control {
        min-height: 40px;
        border: 1px solid;
        border-color: ${(props): string =>
            props.isInvalid
                ? props.theme.deprecated.atoms.dropdown.base.invalid.borderColor
                : props.theme.deprecated.atoms.dropdown.base.borderColor};
        background-color: ${(props): string =>
            props.isInvalid
                ? props.theme.deprecated.atoms.dropdown.base.invalid.backgroundColor
                : props.theme.deprecated.atoms.dropdown.base.backgroundColor};
        box-shadow: none;

        &--is-disabled {
            border-color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.disabled.borderColor};
            background-color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.disabled.backgroundColor};

            .react-select__placeholder {
                color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.disabled.color};
            }
        }

        &::before {
            content: '';
            display: ${(props): string => (props.isMenuOpen ? 'block' : 'none')};
            position: absolute;
            inset: -3px 0 0 -3px;
            border: 2px solid ${(props): string => props.theme.deprecated.atoms.dropdown.base.boxShadow};
            border-bottom-width: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &--is-focused,
        &:hover {
            border-color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.active.borderColor};
        }
    }

    .react-select__input-container {
        color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.color};
    }

    .react-select__menu {
        z-index: ${LAYER.x1};
        margin: -1px 0 0;
        border: 1px solid ${(props): string => props.theme.deprecated.atoms.dropdown.base.active.borderColor};
        box-shadow: none;

        &::before {
            content: '';
            position: absolute;
            inset: -1px -3px -3px;
            border: 2px solid ${(props): string => props.theme.deprecated.atoms.dropdown.base.boxShadow};
            border-top-width: 0;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    .react-select__menu-list {
        padding: 0;
    }

    .react-select__option {
        display: flex;
        align-items: center;

        &--is-selected {
            background-color: ${(props): string =>
                props.isMulti
                    ? props.theme.deprecated.atoms.dropdown.base.backgroundColor
                    : props.theme.deprecated.atoms.dropdown.base.active.backgroundColor};
            color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.active.color};
        }

        &--is-disabled {
            color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.disabled.color};
            cursor: not-allowed;
        }

        &--is-focused {
            background-color: ${(props): string => props.theme.deprecated.atoms.dropdown.base.active.backgroundColor};
        }
    }

    .react-select__indicator,
    .react-select__indicator:hover {
        color: ${(props): string =>
            props.isActiveFilterField
                ? props.theme.deprecated.atoms.dropdown.icon.filter.color
                : props.theme.deprecated.atoms.dropdown.icon.color};
    }

    .react-select__dropdown-indicator {
        display: ${(props): string => {
            if (props.isActiveFilterField) return 'none';
            if (props.shouldShowDropdownIndicator) return 'flex';

            return 'none';
        }};
        transform: ${(props): string => (props.isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
`;

export const NexusWrapper = styled.div<WrapperProps>`
    cursor: ${(props): string => (props.isDisabled ? 'not-allowed' : 'auto')};

    .react-select__control {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;
        min-height: ${theme.space[48]};
        padding: ${theme.space[8]} ${theme.space[16]} ${theme.space[8]} ${theme.space[16]};
        border: none;
        border-bottom: ${theme.borderWidths[2]} solid transparent;
        border-radius: ${theme.radii[4]};
        background-color: ${({ isPrimary }): CSSProperties['backgroundColor'] =>
            isPrimary ? theme.colors['background-global-primary'] : theme.colors['background-global-secondary']};
        font-size: ${theme.fontSizes[16]};
        cursor: pointer;
        gap: ${theme.space[8]};

        &--menu-is-open,
        &--is-focused {
            border-bottom: ${theme.borderWidths[2]} solid ${theme.colors['borders-brand-primary']};
            border-radius: ${theme.radii[4]} ${theme.radii[4]} 0 0;
        }

        .react-select__placeholder {
            margin: 0;
            opacity: 1;
            color: ${theme.colors['text-global-secondary']};
        }

        &:not(&--menu-is-open),
        &:not(&--is-focused) {
            &:hover {
                border-color: transparent;
            }
        }
    }

    .react-select__value-container,
    .react-select__single-value {
        margin: 0;
        padding: 0;
        color: ${theme.colors['text-global-primary']};
    }

    .react-select__menu {
        z-index: ${theme.zIndices.dropdown};
        margin: 0;
        background-color: ${({ isPrimary }): CSSProperties['backgroundColor'] =>
            isPrimary ? theme.colors['background-global-primary'] : theme.colors['background-global-secondary']};
        box-shadow: none;

        &::after {
            content: '';
            position: absolute;
            overflow: hidden;
            border-radius: ${theme.radii[4]};
            box-shadow: 0 0 ${theme.sizes[6]} 0 rgb(0 0 0 / 30%);
            pointer-events: none;
            inset: -${theme.sizes[48]} 0 0 0;
        }
    }

    .react-select__menu-list {
        max-height: 400px;
        padding: 0;
    }

    .react-select__option {
        display: flex;
        align-items: center;
        padding: ${theme.space[8]} ${theme.space[16]};
        font-size: ${theme.fontSizes[16]};
        line-height: ${theme.space[24]};
        cursor: pointer;

        &--is-focused {
            background-color: transparent;
            color: ${theme.colors['text-global-primary']};
        }

        &--is-selected {
            background-color: ${theme.colors['background-brand-tertiary']};
            color: ${theme.colors['text-global-primary']};
        }

        &--is-disabled {
            cursor: not-allowed;
        }

        &:active {
            background-color: transparent;
        }
    }

    .react-select__indicators {
        transform: ${(props): string => (props.isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    }

    .react-select__clear-indicator {
        padding: 0 ${theme.space[8]};
    }
`;

export const OptionContent = styled.div<{ isMulti: boolean }>`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-left: ${(props): string => (props.isMulti ? '10px' : '0')};
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`;

/* It fixes the problem with closing menu when clicking on checkbox */
export const OptionCheckbox = styled(Checkbox)`
    pointer-events: none;
`;

export const ControlIconContainer = styled.div`
    padding: 8px 0 8px 8px;
`;
