import type { Theme } from '@emotion/react';
import type { Theme as ReactSelectTheme } from 'react-select';

export const createTheme =
    (theme: Theme) =>
    (reactSelectTheme: ReactSelectTheme): ReactSelectTheme => ({
        ...reactSelectTheme,
        borderRadius: 2,
        colors: {
            ...reactSelectTheme.colors,
            primary: theme.deprecated.atoms.dropdown.base.active.backgroundColor,
            primary75: theme.deprecated.atoms.dropdown.base.active.backgroundColor,
            primary50: theme.deprecated.atoms.dropdown.base.active.backgroundColor,
            primary25: theme.deprecated.atoms.dropdown.base.active.backgroundColor,
            dangerLight: theme.deprecated.atoms.dropdown.base.invalid.color,
            danger: theme.deprecated.atoms.dropdown.base.invalid.backgroundColor,
        },
    });
