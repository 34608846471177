import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTheme } from '@nexus/lib-react/dist/theme/emotionUtils';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { ForwardedRef, JSX, ReactNode, RefAttributes } from 'react';
import { forwardRef, useId, useMemo, useState } from 'react';
import type { ActionMeta, GroupBase, OnChangeValue, Props as ReactSelectProps } from 'react-select';
import ReactSelect from 'react-select';
import type Select from 'react-select/base';
import { useUpdateEffect } from 'react-use';

import { createTheme } from './createTheme';
import { COMPONENTS as baseComponents } from './Dropdown.components';
import { DropdownContext } from './Dropdown.context';
import { NexusWrapper, Wrapper } from './Dropdown.theme';
import type { DropdownProps, OptionType } from './types';

const DropdownBase = <
    Option extends OptionType = DropdownOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: ReactSelectProps<Option, IsMulti, Group> & DropdownProps<Option>,
    ref: ForwardedRef<Select<Option, IsMulti, Group>>,
): JSX.Element => {
    const {
        icon,
        className,
        components: customComponents,
        shouldTranslateGroupLabel = true,
        shouldTranslateLabels = true,
        shouldShowDropdownIndicator = true,
        variant = 'default',
        dataCy = 'dropdown',
        shouldUseNexusTheme = false,
        onValueChange,
        trackOnMenuOpen,
        trackOnMenuClose,
        trackOnSelect,
        ...delegatedProps
    } = props;
    const id = useId();
    const theme = useTheme();
    const [t] = useTranslations();
    const [isMenuOpen, setIsMenuOpen] = useState(() => props.defaultMenuIsOpen);

    const handleMenuClose = (): void => {
        setIsMenuOpen(false);
        delegatedProps.onMenuClose?.();
    };

    const handleMenuOpen = (): void => {
        setIsMenuOpen(true);
        delegatedProps.onMenuOpen?.();
    };

    const handleChange = (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>): void => {
        trackOnSelect?.(Array.isArray(newValue) ? newValue[0] : newValue || null);
        props.onChange?.(newValue, actionMeta);
        onValueChange?.();
    };

    const labelTransformer = ({ label }: Option): string => {
        return shouldTranslateLabels ? t(label) : label;
    };

    const groupLabelTransformer = ({ label }: Group): ReactNode => {
        return shouldTranslateGroupLabel && label ? t(label) : label;
    };

    const components = useMemo(() => {
        return { ...baseComponents, ...customComponents };
    }, [customComponents]);

    useUpdateEffect(() => {
        if (isMenuOpen) {
            trackOnMenuOpen?.();
        } else {
            trackOnMenuClose?.();
        }
    }, [isMenuOpen]);

    const ReactSelectWrapper = shouldUseNexusTheme ? NexusWrapper : Wrapper;

    return (
        <DropdownContext.Provider value={{ icon }}>
            <ReactSelectWrapper
                isDisabled={props.isDisabled}
                isInvalid={variant === 'invalid' && !props.isDisabled}
                isMenuOpen={isMenuOpen}
                isMulti={props.isMulti}
                isPrimary={variant === 'primary'}
                isActiveFilterField={variant === 'filter' && !!props.value}
                shouldShowDropdownIndicator={shouldShowDropdownIndicator}
                className={className}
                data-cy={dataCy}
            >
                <ReactSelect
                    id={id}
                    inputId={`${id}-input`}
                    instanceId={`${id}-instance`}
                    {...delegatedProps}
                    aria-invalid={variant === 'invalid'}
                    classNamePrefix="react-select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={!props.isMulti}
                    components={components}
                    theme={createTheme(theme)}
                    formatGroupLabel={groupLabelTransformer}
                    getOptionLabel={labelTransformer}
                    onMenuClose={handleMenuClose}
                    onMenuOpen={handleMenuOpen}
                    onChange={handleChange}
                    ref={ref}
                    noOptionsMessage={(): string => t('frontend.shared.dropdown.no-options-message')}
                />
            </ReactSelectWrapper>
        </DropdownContext.Provider>
    );
};

// "as" temporarily solves issue with propagating generics, more on: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
// export const Dropdown = forwardRef(DropdownBase);

export const Dropdown = forwardRef(DropdownBase) as <
    Option extends OptionType = DropdownOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    render: ReactSelectProps<Option, IsMulti, Group> &
        DropdownProps<Option> &
        RefAttributes<Select<Option, IsMulti, Group>>,
) => JSX.Element;
