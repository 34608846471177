import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledAnchor = styled.a`
    color: inherit;
    text-decoration: none;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 0;
    }
`;

const ISLAND_POSITION = css`
    position: relative;
    z-index: 1;
`;

export const InteractiveIslandContainer = styled.div<{ shouldAffectEntireContainer: boolean }>`
    ${({ shouldAffectEntireContainer }): SerializedStyles | undefined => {
        return shouldAffectEntireContainer ? ISLAND_POSITION : undefined;
    }}

    a,
    button,
    [role='button'],
    [data-interactive-island-element="true"] {
        ${({ shouldAffectEntireContainer }): SerializedStyles | undefined =>
            shouldAffectEntireContainer ? undefined : ISLAND_POSITION}
    }
`;
