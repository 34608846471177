import { SEO_ESTATE } from '@type/search/filters/seoEstate';

/**
 * getEstateTranslationKeyPrefix will return estate translation key conditioned by estate type
 * @param estate -  estate type
 * @returns - object with estate translation key
 */
export const getEstateTranslationKeyPrefix = (estate: string): string => {
    const estateTranslationKeys = {
        [SEO_ESTATE.flat]: 'frontend.shared.seo.estate-label-flat',
        [SEO_ESTATE.studioFlat]: 'frontend.shared.seo.estate-label-studio-flat',
        [SEO_ESTATE.house]: 'frontend.shared.seo.estate-label-house',
        [SEO_ESTATE.investment]: 'frontend.shared.seo.estate-label-investment',
        [SEO_ESTATE.room]: 'frontend.shared.seo.estate-label-room',
        [SEO_ESTATE.terrain]: 'frontend.shared.seo.estate-label-terrain',
        [SEO_ESTATE.commercialProperty]: 'frontend.shared.seo.estate-label-commercial-property',
        [SEO_ESTATE.hall]: 'frontend.shared.seo.estate-label-hall',
        [SEO_ESTATE.garage]: 'frontend.shared.seo.estate-label-garage',
        [SEO_ESTATE.office]: 'frontend.shared.seo.estate-label-office',
        [SEO_ESTATE.villas]: 'frontend.shared.seo.estate-label-villas',
        [SEO_ESTATE.penthouses]: 'frontend.shared.seo.estate-label-penthouses',
    };

    return estateTranslationKeys[estate as keyof typeof estateTranslationKeys];
};
